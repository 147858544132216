<template>
  <div class="background-image" id="app">
    <div class="window">
      <div class="assistant-container">
        <img src="@/assets/Peedy.webp" width="75px" alt="Assistant" class="assistant-image" />
      </div>
      <div class="title-bar">
        <div class="title-bar-text">Peedy</div>
        <div class="title-bar-controls">
          <button aria-label="Minimize"></button>
          <button aria-label="Maximize"></button>
          <button aria-label="Close"></button>
        </div>
      </div>
      <div class="window-body">
        <p style="font-weight: 900; font-size:large;">$PEEDY</p>
        <p style="font-weight: 600; font-size:medium;">CA:</p>
        <p style="font-weight: 600; font-size:medium;">SUPPLY:1 000 000</p>

        <p style="font-weight: 600; font-size:medium;">LIQUIDITY: BURNED</p>
        <p style="font-weight: 600; font-size:medium;">STARTING LP: 1 ETH</p>
        <p style="font-weight: 600; font-size:medium;">PRICE: WE BALLIN</p>
        <div class="flex justify-center gap-3" style="padding-top: 80px;">
          <a href="https://ethervista.app/" target="_blank" ><button style="padding: 12px 24px; font-size: 16px;">SWAP</button></a>
          <a href="https://ethervista.app/" target="_blank" ><button style="padding: 12px 24px; font-size: 16px;">DEXSCREENER</button></a>
          <a href="https://ethervista.app/" target="_blank" ><button style="padding: 12px 24px; font-size: 16px;">TWITTER</button></a>
        </div>

      </div>
    </div>
    <WindowsToolbar />

  </div>
</template>

<script>
import WindowsToolbar from './components/WindowsToolBar.vue';

export default {
  name: 'App',
  components: {
    WindowsToolbar
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
}
</style>

<style>
body {
  background-color: #57A8A8;
  background-size: 100% 100%; /* Stretches the image to fit both width and height */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  background-position: center center; /* Centers the image */
  width: 100vw;  /* Full viewport width */
  height: 85vh; /* Full viewport height */
  margin: 0;
  padding: 0;

}
.window {
  margin-top: 15vh;
  position: relative; /* This makes it the positioning context for absolute children */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Align center */
  width: 70%; /* Default width */
  max-width: 600px; /* Adjust based on your preference for max width */
  min-height: 500px; /* Optional: if you want a minimum height */
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2); /* Optional: adds shadow for better visibility */
}
.assistant-container {
  position: absolute; /* Positioned absolutely within the .window */
  right: 30px; /* 10px from the right edge of the .window */
  top: -100px; /* 10px from the top edge of the .window */
  z-index: 10; /* Make sure it's above other content in the .window */
}
@media (max-width: 600px) {
  .window {
    width: 100%; /* Full width on smaller screens */
    left: 0; /* Reset left for full width */
    transform: translateX(0); /* Reset transform for full width */
    max-width: none; /* Remove max width constraint */
  }
}
</style>